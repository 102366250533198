/* loader start  */
#preloader {
    position: fixed;
    z-index: 1091;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,.2);
  }
  
  #loader {
    display: block;
    position: relative;
    left: 50% !important;
    top: 50% !important;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    background-color: #f17c2b !important;
  }
  
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* loader end  */